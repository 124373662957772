.main {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 20px 0;
    border-radius: 50px;

    @media (min-width: 992px) {
        flex-direction: row;
    }
}

.icon {
    flex-grow: 0;

    margin: 10px 25px;

    color: black;
    font-size: 72px;
}

.message {
    flex-grow: 1;

    margin: 10px 25px;

    text-align: center;

    h1 {
        margin: 0;
    }
}

.statistics {
    display: flex;
    flex-wrap: wrap;

    margin: -10px -10px 0;
}

.statistic {
    flex: 1 1 max-content;

    margin: 10px 10px;
    padding: 0 20px;

    text-align: center;

    border: 1px solid #ccc;
    border-radius: 20px;
    background-color: white;

    h1 {
        font-size: 72px;
        margin: 10px 0;
    }

    p {
        color: black;
        margin: 0 0 10px;
    }
}

.buttons {
    display: flex;
    flex-wrap: wrap;

    margin: 0 -10px 20px;
}

.button {
    flex: 1 1 max-content;

    margin: 10px 10px;
    padding: 10px 20px;

    cursor: pointer;

    text-align: center;
    font-size: 2em;
    text-decoration: none;

    border-radius: 20px;
    background-color: #1BB3FF;
    color: black;

    &.buttonPrimary {
        flex-basis: 100%;
    }

    &:hover {
        color: black;
    }
}

.card {
    h1 {
        text-align: center;
        margin-top: 40px;
    }

    .subtitle {
        font-style: italic;
        text-align: center;
    }

    line-height: 150%;
}

.tables {
    display: flex;
    flex-wrap: wrap;

    margin: 0 -10px 20px;
}

.table {
    flex: 1 1 100%;

    margin: 10px;
    padding: 0 20px 20px;

    box-sizing: border-box;
    border: 5px solid #1BB3FF;
    border-radius: 20px;

    text-align: center;

    table {
        width: 100%;

        border-collapse: collapse;
    }

    td, th {
        border: 2px solid #ccc;
        padding: 0.5rem 1rem;
        color: black;
    }

    @media (min-width: 992px) {
        flex-basis: 0;
    }
}

.userHeading {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    margin: 20px 0;
    padding: 20px;

    background: #1BB3FF;
    border-radius: 40px;
}

.card {
    flex-basis: 500px;

    iframe {
        width: min(500px, 100%);
        height: 300px;
        border: none;
        border-radius: 20px;
    }
}

.userInfo {
    flex: 1 1;
    align-self: center;

    text-align: center;

    h1 {
        font-size: 60px;
        margin: 0.25em;
    }

    h3 {
        font-size: 30px;
    }

    a {
        color: black;
    }
}

.balance {
    text-align: center;
}